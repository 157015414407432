import { resetHashHistory } from '~/utils';
import { useMainStore } from '~/stores/mainStore';
import { useDisconnect, useWeb3Modal } from '@web3modal/ethers/vue';

export default () => {
  const store = useMainStore();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();

  const login = async (): Promise<void> => {
    try {
      await open();

      useTrackEvent('login');
    } catch (error) {
      console.error('error on login', error);
    }
  };

  const logout = async (): Promise<void> => {
    store.setUserData(null);
    clearNuxtData('userData');
    resetHashHistory();
    await disconnect();
  };

  return { logout, login };
};
